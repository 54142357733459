import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { agregarToken } from "../../features/ui/uiSlice";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store";
import { Spinner } from "../Spinner";

export const BarTopComponent = () => {
  const { usuario } = useSelector((state: RootState) => state.ui);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const menuRef = useRef(null);
  const handleProfileClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const cerrarSesion = () => {
    dispatch(agregarToken(""));
    navigate("/auth/login", { replace: true });
  };

  const handleEditProfile = () => {
    setIsMenuOpen(false);
    navigate("/home/editar-perfil");
  };
  return (
    <div className="max-w-full">
      <div className="flex items-center justify-between bg-white  px-4 py-1 shadow-sm">
        <div className="font-medium text-gray-600"></div>

        {usuario && (
          <div className="relative" ref={menuRef}>
            <button
              onClick={handleProfileClick}
              className="flex items-center space-x-4 focus:outline-none"
            >
              <div className="text-right">
                <p className="text-sm font-semibold text-gray-800">
                  {usuario.nombre} {usuario.apellido_paterno}{" "}
                  {usuario.apellido_materno}
                </p>
                <p className="text-sm text-gray-500">
                  <span className="mr-2">{usuario.email}</span>
                  <span className="rounded-lg bg-primary px-2 text-white">
                    {usuario.profile} / {usuario.area}
                  </span>
                </p>
              </div>
              <div className="flex h-10 w-10 items-center justify-center rounded-full">
                <img src={usuario.fc_avatar} />
              </div>
            </button>

            {isMenuOpen && (
              <div className="absolute right-0 !z-[999] mt-2 w-48 rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5">
                <button
                  onClick={handleEditProfile}
                  className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                >
                  Editar perfil
                </button>
                <button
                  onClick={cerrarSesion}
                  className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                >
                  Cerrar sesión
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
