import {
  useCalificarDocumentoMutation,
  useCargaDocumentoMutation,
  useLazyGetDocsListQuery,
} from "../services/docs";
import { useNavigate } from "react-router-dom";
import { Spinner } from "./Spinner";
import { useEffect, useState } from "react";
import Button from "./ui/Button";
import { toastr } from "react-redux-toastr";
import { Icon } from "./ui/Icon";

export const ItemDocumentoValidar = ({
  doc,
  i,
  valor,
  siniestro,
  tramite_id,
}) => {
  const [
    guardaCalificacion,
    { isLoading: isLoadingCarga, error: errorCarga, isSuccess, reset },
  ] = useCalificarDocumentoMutation();
  const [tigger] = useLazyGetDocsListQuery();
  const [cal, setCal] = useState(0);
  const onChange = (e: any) => {
    valor[i].comentario = e.target.value;
  };
  const onClickRadio = (e: any) => {
    valor[i].respuesta = e.target.value;
  };
  const rectificar = (indice) => {
    console.log(valor[i].calificado);
    setCal(1);
  };
  const guardarCalificacion = async () => {
    if (!valor[i].respuesta) {
      toastr.error("Debes de agregar una calificación");
      return;
    }
    if (valor[i].respuesta === "2" && !valor[i].comentario) {
      toastr.error("Debes de agregar un comentario");
      return;
    }
    const resp = await guardaCalificacion({
      siniestro,
      tramite_id: +tramite_id,
      documento_id: doc.id,
      calificacion_id: Number(valor[i].respuesta),
      comentario: valor[i].comentario,
      adicional: doc.adicional,
    })
      .unwrap()
      .catch((err: any) => {
        if (err.status === 400) {
          if (err.data.message) {
            if (Array.isArray(err.data.message)) {
              err.data.message.map((m: any) => {
                toastr.error(m);
              });
            } else {
              toastr.error(err.data.message);
            }
          }
        } else {
          toastr.error(err.data.message);
        }
      });
    if (resp) {
      setCal(0);
      toastr.info(resp.message);
      tigger({ siniestro, tramite: tramite_id });
    }
  };
  let color = "bg-gray";
  let urlDoc = "";

  if (doc.documento) {
    if (doc.documento.length > 0) {
      urlDoc = doc.documento[0].fc_ruta_documento;
      valor[i].calificado = doc.documento[0].fi_calificado;

      switch (doc.documento[0].fi_rechazo_digital) {
        case 0:
          color = "bg-green-400";
          break;
        case 1:
          color = "bg-primary";
          break;
        case 2:
          color = "bg-yellow-400";
          break;
        default:
          color = "bg-gray";
          break;
      }
    }
  }

  return (
    <div
      key={doc.id}
      className="w-full flex-row items-center rounded-xl border bg-white p-2 shadow"
    >
      <div className={`w-12 ${color} h-2 overflow-hidden rounded-xl`}></div>
      <div className="relative flex items-center space-x-1">
        {isLoadingCarga && <Spinner />}
        {!isLoadingCarga && (
          <>
            {/* <input onChange={(e) => onChange(e, doc, i)} className="block w-full mb-5 text-xs text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="small_size" type="file" /> */}
          </>
        )}
      </div>
      <div className="flex-grow p-3">
        <div className="flex w-full justify-end">
          {urlDoc && (
            <a
              className="mx-4 rounded bg-primary-light p-1 text-right text-sm text-white"
              href={urlDoc}
              target="_blank"
            >
              Ver Documento
            </a>
          )}
        </div>
        <div className="font-semibold text-gray-700">
          {doc.fc_nombre_documento}
        </div>
        <div className="text-sm text-gray-500">
          {doc.fc_descripcion_documento}
        </div>

        {doc.documento &&
          doc.documento.length > 0 &&
          valor[i].calificado === 1 &&
          cal === 0 && (
            <div>
              <span>¿El documento es correcto ?</span>
              <div>
                {doc.documento[0].fi_rechazo_digital === 1 ? "Si" : "No"}
              </div>
              <div>
                <label htmlFor="comentarios">Comentarios:</label>
                <div>{doc.documento[0].fc_comentarios_rechazo}</div>
                <Button
                  className="my-5 bg-danger text-gray-500"
                  onClick={() => rectificar(i)}
                >
                  Rectificar
                </Button>
              </div>
            </div>
          )}
        {(!valor[i].calificado || cal === 1) && (
          <div>
            <span className="my-5 flex w-full font-semibold">
              El documento esta correcto?
            </span>
            <ul className="w-full items-center rounded-lg text-sm font-medium   text-white sm:flex ">
              <li className="w-full rounded border-primary-light">
                <div className="flex items-center pl-3">
                  <input
                    onClick={onClickRadio}
                    id={"respuesta_si" + doc.id}
                    type="radio"
                    value="1"
                    name="respuesta"
                    className="h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:ring-offset-gray-700 dark:focus:ring-blue-600 dark:focus:ring-offset-gray-700"
                  />
                  <label
                    htmlFor={"respuesta_si" + doc.id}
                    className="ml-2 w-full py-3 text-sm font-medium text-primary"
                  >
                    Correcto{" "}
                  </label>
                </div>
              </li>
              <li className="w-full rounded border-danger">
                <div className="flex items-center pl-3">
                  <input
                    onClick={onClickRadio}
                    id={"respuesta_no" + doc.id}
                    type="radio"
                    value="2"
                    name="respuesta"
                    className="h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:ring-offset-gray-700 dark:focus:ring-blue-600 dark:focus:ring-offset-gray-700"
                  />
                  <label
                    htmlFor={"respuesta_no" + doc.id}
                    className="ml-2 w-full py-3 text-sm font-medium text-danger"
                  >
                    Incorrecto{" "}
                  </label>
                </div>
              </li>
            </ul>
            <div>
              <label htmlFor="comentarios" className="flex py-2">
                Comentarios:
              </label>
              <textarea
                className="w-full rounded border-gray-300"
                onChange={onChange}
                name="comentarios"
                id=""
                cols={40}
                rows={2}
              ></textarea>
              <Button
                className="my-5 text-center"
                onClick={() => guardarCalificacion()}
              >
                <Icon variante={"guardar"} />
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
