import { AnimatePresence, motion, MotionConfig } from "framer-motion";
import { Outlet, useNavigate, useLocation, Navigate } from "react-router-dom";
import { customTransition } from "../lib/animations";
import Menu from "../components/ui/Menu";
import { BarTopComponent } from "../components/ui/BarTop";

export const PrivateLayout = () => {
  const path = useLocation();

  return (
    <MotionConfig transition={customTransition}>
      <div className="min-w-screen max-w-screen relative h-screen max-h-screen min-h-screen w-screen overflow-clip shadow">
        <motion.div
          layout
          className="flex max-h-full min-h-full overflow-y-auto bg-gray-ultra-light"
        >
          <Menu />
          <AnimatePresence mode="popLayout">
            <motion.div
              key={path.pathname}
              layoutId={path.pathname}
              initial={{ opacity: 0, visibility: "hidden" }}
              animate={{
                opacity: 1,
                transition: { delay: 0.25 },
                visibility: "visible",
              }}
              exit={{ opacity: 0, visibility: "hidden" }}
              transition={{
                duration: 0.5,
              }}
              className="relative h-full w-full "
            >
              <BarTopComponent />
              <div className="p-4 xl:p-7">
                <Outlet />
              </div>
            </motion.div>
          </AnimatePresence>
        </motion.div>
      </div>
    </MotionConfig>
  );
};
