import { fecha_actual } from "../hooks/elapsed";
import { Icon } from "./ui/Icon";

export const ItemDocumentoComponente = ({ doc }) => {
  let urlDoc = "";
  let color = "";
  let fi_cargado = 0;
  let fi_cargado_sftp = 0;
  let fc_sftp = "";
  let fd_cargado = "";
  let fd_carga_sftp = "";
  if (doc.documento) {
    if (doc.documento.length > 0) {
      urlDoc = doc.documento[0].fc_ruta_documento;
      fi_cargado = 1;
      fd_cargado = doc.documento[0].fd_fecha_carga;
      fi_cargado_sftp = doc.documento[0].fi_cargado_sftp;
      fc_sftp = doc.documento[0].fc_sftp;
      fd_carga_sftp = doc.documento[0].fd_carga_sftp;
      switch (doc.documento[0].fi_rechazo_digital) {
        case 0:
          color = "bg-green-400";
          break;
        case 1:
          color = "bg-primary";
          break;
        case 2:
          color = "bg-yellow-400";
          break;
        default:
          color = "bg-gray";
          break;
      }
    }
  }

  return (
    <div className="card" key={doc.id}>
      <div className={`w-12 ${color} h-2 overflow-hidden rounded-xl`}></div>
      <div className="mt-5 flex">
        <div className="flex-item">
          <p className="font-bold">
            {doc.fi_documento}. {doc.fc_nombre_documento}
          </p>
          <p>{doc.fc_descripcion_documento}</p>
        </div>
      </div>
      {urlDoc && (
        <div className="flex-item mt-5">
          <a
            className="lg:w-1/8 flex w-1/4  justify-center rounded bg-primary-light py-2 text-right text-sm text-white md:w-1/6"
            href={urlDoc}
            target="_blank"
          >
            <Icon variante={"descarga"} />
          </a>
        </div>
      )}
      {fi_cargado === 1 && (
        <>
          <div className="flex-item mt-5">
            <p className="text-sm font-bold">Fecha de carga:</p>
            <p className="text-sm text-gray-600"> {fecha_actual(fd_cargado)}</p>
          </div>
          <div className="flex-item mt-5">
            <p className="text-sm font-bold">Fecha de carga SFTP:</p>
            <p className="text-sm text-gray-600">
              {" "}
              {fecha_actual(fd_carga_sftp)}
            </p>
          </div>
          <div className="flex-item mt-5">
            <p className="text-sm font-bold">Ruta:</p>
            <p className="text-sm text-gray-600"> {fc_sftp}</p>
          </div>
        </>
      )}
    </div>
  );
};
