import * as React from "react";

export interface IIconProps {
  variante:
    | "calendario"
    | "circulo"
    | "chevron-down"
    | "chevron-right"
    | "casa"
    | "circulo-aprobado"
    | "flecha-atras"
    | "folder-con-usuario"
    | "campana"
    | "lupa"
    | "engrane"
    | "menu"
    | "x"
    | "descarga"
    | "advertencia"
    | "aprobado"
    | "chevron-left"
    | "pendiente"
    | "cubo"
    | "file-text"
    | "nuevo"
    | "wakaway"
    | "reportes"
    | "lock"
    | "user-close"
    | "carga"
    | "eliminar"
    | "mas"
    | "guardar"
    | "equipo";
  size?: number;
  color?: string;
}

export const Icon = ({ variante, size = 24 }: IIconProps): JSX.Element => {
  const iconSize = size.toString();

  switch (variante) {
    case "descarga":
      return (
        <svg
          className="relative shrink-0 overflow-visible"
          width={iconSize}
          height={iconSize}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25 28H7C6.73478 28 6.48043 27.8946 6.29289 27.7071C6.10536 27.5196 6 27.2652 6 27V5C6 4.73478 6.10536 4.48043 6.29289 4.29289C6.48043 4.10536 6.73478 4 7 4H19L26 11V27C26 27.2652 25.8946 27.5196 25.7071 27.7071C25.5196 27.8946 25.2652 28 25 28Z"
            stroke={"currentColor"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19 4V11H26"
            stroke={"currentColor"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 15V23"
            stroke={"currentColor"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13 20L16 23L19 20"
            stroke={"currentColor"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "menu":
      return (
        <svg
          className="relative shrink-0 overflow-visible"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="4" y="7" width="24" height="2" fill={"currentColor"} />
          <rect x="4" y="15" width="24" height="2" fill={"currentColor"} />
          <rect x="4" y="23" width="24" height="2" fill={"currentColor"} />
        </svg>
      );
    case "lupa":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={iconSize}
          height={iconSize}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-search"
        >
          <circle cx="11" cy="11" r="8"></circle>
          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
        </svg>
      );
    case "file-text":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={iconSize}
          height={iconSize}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="2"
          strokeLinejoin="round"
          className="feather feather-file-text"
        >
          <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
          <polyline points="14 2 14 8 20 8"></polyline>
          <line x1="16" y1="13" x2="8" y2="13"></line>
          <line x1="16" y1="17" x2="8" y2="17"></line>
          <polyline points="10 9 9 9 8 9"></polyline>
        </svg>
      );
    case "pendiente":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={iconSize}
          height={iconSize}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-user-check"
        >
          <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
          <circle cx="8.5" cy="7" r="4"></circle>
          <polyline points="17 11 19 13 23 9"></polyline>
        </svg>
      );
    case "cubo":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={iconSize}
          height={iconSize}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-codesandbox"
        >
          <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
          <polyline points="7.5 4.21 12 6.81 16.5 4.21"></polyline>
          <polyline points="7.5 19.79 7.5 14.6 3 12"></polyline>
          <polyline points="21 12 16.5 14.6 16.5 19.79"></polyline>
          <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
          <line x1="12" y1="22.08" x2="12" y2="12"></line>
        </svg>
      );
    case "x":
      return (
        <svg
          className="relative shrink-0 overflow-visible"
          width={iconSize}
          height={iconSize}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.9023 5.31421L5.40234 18.8142"
            stroke={"currentColor"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.9023 18.8142L5.40234 5.31421"
            stroke={"currentColor"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "engrane":
      return (
        <svg
          className="relative shrink-0 overflow-visible"
          width={iconSize}
          height={iconSize}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 22C19.3137 22 22 19.3137 22 16C22 12.6863 19.3137 10 16 10C12.6863 10 10 12.6863 10 16C10 19.3137 12.6863 22 16 22Z"
            stroke={"currentColor"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.9617 8.13745C23.2784 8.42912 23.5784 8.72912 23.8617 9.03745L27.2742 9.52495C27.8302 10.4906 28.2588 11.5242 28.5492 12.6L26.4742 15.3625C26.4742 15.3625 26.5117 16.2125 26.4742 16.6375L28.5492 19.4C28.2602 20.4762 27.8315 21.5099 27.2742 22.475L23.8617 22.9625C23.8617 22.9625 23.2742 23.5749 22.9617 23.8624L22.4742 27.275C21.5086 27.831 20.475 28.2595 19.3992 28.5499L16.6367 26.475C16.2125 26.5125 15.7859 26.5125 15.3617 26.475L12.5992 28.5499C11.523 28.2609 10.4892 27.8322 9.52422 27.275L9.03672 23.8624C8.72839 23.5708 8.42839 23.2708 8.13672 22.9625L4.72422 22.475C4.1682 21.5093 3.73963 20.4757 3.44922 19.4L5.52422 16.6375C5.52422 16.6375 5.48672 15.7875 5.52422 15.3625L3.44922 12.6C3.73828 11.5237 4.16692 10.49 4.72422 9.52495L8.13672 9.03745C8.42839 8.72912 8.72839 8.42912 9.03672 8.13745L9.52422 4.72495C10.4899 4.16893 11.5235 3.74036 12.5992 3.44995L15.3617 5.52495C15.7859 5.48744 16.2125 5.48744 16.6367 5.52495L19.3992 3.44995C20.4754 3.73902 21.5092 4.16765 22.4742 4.72495L22.9617 8.13745Z"
            stroke={"currentColor"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "campana":
      return (
        <svg
          className="relative shrink-0 overflow-visible"
          width={iconSize}
          height={iconSize}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 24C12 25.0609 12.4214 26.0783 13.1716 26.8284C13.9217 27.5786 14.9391 28 16 28C17.0609 28 18.0783 27.5786 18.8284 26.8284C19.5786 26.0783 20 25.0609 20 24"
            stroke={"currentColor"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.99984 13C6.99984 10.6131 7.94806 8.32387 9.63588 6.63604C11.3237 4.94821 13.6129 4 15.9998 4C18.3868 4 20.676 4.94821 22.3638 6.63604C24.0516 8.32387 24.9998 10.6131 24.9998 13C24.9998 17.4775 26.0373 21.075 26.8623 22.5C26.95 22.6518 26.9962 22.8239 26.9963 22.9991C26.9965 23.1744 26.9506 23.3466 26.8632 23.4985C26.7759 23.6504 26.6501 23.7767 26.4986 23.8647C26.3471 23.9527 26.1751 23.9994 25.9998 24H5.99984C5.82482 23.9989 5.65314 23.952 5.50195 23.8638C5.35077 23.7756 5.22539 23.6492 5.13833 23.4974C5.05127 23.3456 5.00559 23.1735 5.00586 22.9985C5.00613 22.8235 5.05233 22.6516 5.13984 22.5C5.96359 21.075 6.99984 17.4763 6.99984 13Z"
            stroke={"currentColor"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "calendario":
      return (
        <svg
          className="relative shrink-0 overflow-visible"
          width={iconSize}
          height={iconSize}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M26 5H6C5.44772 5 5 5.44772 5 6V26C5 26.5523 5.44772 27 6 27H26C26.5523 27 27 26.5523 27 26V6C27 5.44772 26.5523 5 26 5Z"
            stroke={"currentColor"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22 3V7"
            stroke={"currentColor"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 3V7"
            stroke={"currentColor"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5 11H27"
            stroke={"currentColor"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "circulo":
      return (
        <svg
          className="relative shrink-0 overflow-visible"
          width={iconSize}
          height={iconSize}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
            stroke={"currentColor"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "chevron-down":
      return (
        <svg
          className="relative shrink-0 overflow-visible"
          width={iconSize}
          height={iconSize}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M26 12L16 22L6 12"
            stroke={"currentColor"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "casa":
      return (
        <svg
          className="relative shrink-0 overflow-visible"
          width={iconSize}
          height={iconSize}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 26V20C19 19.7348 18.8946 19.4805 18.7071 19.2929C18.5196 19.1054 18.2652 19 18 19H14C13.7348 19 13.4804 19.1054 13.2929 19.2929C13.1054 19.4805 13 19.7348 13 20V26C13 26.2652 12.8946 26.5196 12.7071 26.7071C12.5196 26.8947 12.2652 27 12 27H6C5.73478 27 5.48043 26.8947 5.29289 26.7071C5.10536 26.5196 5 26.2652 5 26V14.4375C5.00224 14.2991 5.03215 14.1626 5.08796 14.0359C5.14378 13.9093 5.22437 13.7951 5.325 13.7L15.325 4.61252C15.5093 4.44387 15.7501 4.35034 16 4.35034C16.2499 4.35034 16.4907 4.44387 16.675 4.61252L26.675 13.7C26.7756 13.7951 26.8562 13.9093 26.912 14.0359C26.9679 14.1626 26.9978 14.2991 27 14.4375V26C27 26.2652 26.8946 26.5196 26.7071 26.7071C26.5196 26.8947 26.2652 27 26 27H20C19.7348 27 19.4804 26.8947 19.2929 26.7071C19.1054 26.5196 19 26.2652 19 26Z"
            stroke={"currentColor"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "chevron-right":
      return (
        <svg
          className="relative shrink-0 overflow-visible"
          width={iconSize}
          height={iconSize}
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.5 16.25L13.75 10L7.5 3.75"
            stroke={"currentColor"}
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "aprobado":
      return (
        <svg
          className="relative shrink-0 overflow-visible"
          width={iconSize}
          height={iconSize}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
            stroke={"currentColor"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11 17L14 20L21 13"
            stroke={"currentColor"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "folder-con-usuario":
      return (
        <svg
          className="relative shrink-0 overflow-visible"
          width={iconSize}
          height={iconSize}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23.5 24.5C25.1569 24.5 26.5 23.1569 26.5 21.5C26.5 19.8431 25.1569 18.5 23.5 18.5C21.8431 18.5 20.5 19.8431 20.5 21.5C20.5 23.1569 21.8431 24.5 23.5 24.5Z"
            stroke={"currentColor"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.625 27.5C19.8453 26.6396 20.3457 25.877 21.0473 25.3324C21.7489 24.7878 22.6118 24.4922 23.5 24.4922C24.3882 24.4922 25.2511 24.7878 25.9527 25.3324C26.6543 25.877 27.1547 26.6396 27.375 27.5"
            stroke={"currentColor"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4 10V7.00001C4 6.73479 4.10536 6.48044 4.29289 6.2929C4.48043 6.10536 4.73478 6.00001 5 6.00001H11.5875C11.7174 5.99955 11.846 6.02471 11.9661 6.07404C12.0862 6.12338 12.1955 6.19591 12.2875 6.28751L16 10"
            stroke={"currentColor"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.075 26H4.925C4.80353 26 4.68324 25.9761 4.57102 25.9296C4.45879 25.8831 4.35682 25.815 4.27093 25.7291C4.18503 25.6432 4.1169 25.5412 4.07041 25.429C4.02393 25.3168 4 25.1965 4 25.075V10H27C27.2652 10 27.5196 10.1054 27.7071 10.2929C27.8946 10.4804 28 10.7348 28 11V15"
            stroke={"currentColor"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "advertencia":
      return (
        <svg
          className="relative shrink-0 overflow-visible"
          width={iconSize}
          height={iconSize}
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.8014 5.0275L28.7339 24.0112C29.5001 25.3487 28.5101 27 26.9326 27H5.06763C3.49013 27 2.50013 25.3487 3.26638 24.0112L14.1989 5.0275C14.9864 3.6575 17.0139 3.6575 17.8014 5.0275Z"
            stroke={"currentColor"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 18V13"
            stroke={"currentColor"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 24C16.8284 24 17.5 23.3284 17.5 22.5C17.5 21.6716 16.8284 21 16 21C15.1716 21 14.5 21.6716 14.5 22.5C14.5 23.3284 15.1716 24 16 24Z"
            fill={"currentColor"}
          />
        </svg>
      );
    case "chevron-left":
      return (
        <svg
          className="relative shrink-0 overflow-visible"
          width={iconSize}
          height={iconSize}
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.5 16.25L13.75 10L7.5 3.75"
            stroke="currentColor"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="scale(-1, 1) translate(-20, 0)"
          />
        </svg>
      );
    case "nuevo":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={iconSize}
          height={iconSize}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-user-plus"
        >
          <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
          <circle cx="8.5" cy="7" r="4"></circle>
          <line x1="20" y1="8" x2="20" y2="14"></line>
          <line x1="23" y1="11" x2="17" y2="11"></line>
        </svg>
      );
    case "wakaway":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={iconSize}
          height={iconSize}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-bookmark"
        >
          <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
        </svg>
      );
    case "reportes":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={iconSize}
          height={iconSize}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-save"
        >
          <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
          <polyline points="17 21 17 13 7 13 7 21"></polyline>
          <polyline points="7 3 7 8 15 8"></polyline>
        </svg>
      );
    case "user-close":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width={iconSize}
          height={iconSize}
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-user-x"
        >
          <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
          <circle cx="8.5" cy="7" r="4"></circle>
          <line x1="18" y1="8" x2="23" y2="13"></line>
          <line x1="23" y1="8" x2="18" y2="13"></line>
        </svg>
      );
    case "lock":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          width={iconSize}
          height={iconSize}
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-lock"
        >
          <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
          <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
        </svg>
      );
    case "equipo":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={iconSize}
          height={iconSize}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-stop-circle"
        >
          <circle cx="12" cy="12" r="10"></circle>
          <rect x="9" y="9" width="6" height="6"></rect>
        </svg>
      );
    case "carga":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          width={iconSize}
          height={iconSize}
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          className="feather feather-upload"
        >
          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
          <polyline points="17 8 12 3 7 8"></polyline>
          <line x1="12" y1="3" x2="12" y2="15"></line>
        </svg>
      );
    case "eliminar":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width={iconSize}
          height={iconSize}
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          className="feather feather-x-circle"
        >
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="15" y1="9" x2="9" y2="15"></line>
          <line x1="9" y1="9" x2="15" y2="15"></line>
        </svg>
      );
    case "mas":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          width={iconSize}
          height={iconSize}
          className="feather feather-plus-circle"
        >
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="12" y1="8" x2="12" y2="16"></line>
          <line x1="8" y1="12" x2="16" y2="12"></line>
        </svg>
      );
    case "guardar":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          width={iconSize}
          height={iconSize}
          className="feather feather-save"
        >
          <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
          <polyline points="17 21 17 13 7 13 7 21"></polyline>
          <polyline points="7 3 7 8 15 8"></polyline>
        </svg>
      );
    default:
      return <></>;
  }
};
