import { Spinner } from "../../../components/Spinner";
import {
  useBuscarTramiteMutation,
  useGetBusquedaQuery,
  usePostAvatarMutation,
} from "../../../services/docs";
import { useForm } from "../../../hooks/useForm";
import { useNavigate } from "react-router-dom";
import { Label } from "../../../components/ui/Label";
import { Input } from "../../../components/ui/Input";
import Button from "../../../components/ui/Button";
import { toastr } from "react-redux-toastr";
import { TablaTramitesComponent } from "../../../components/TablaTramitesComponent";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { agregarAvatar } from "../../../features/ui/uiSlice";

export const EditarPerfilScreen = () => {
  const { usuario } = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();
  const { formulario, handleChange } = useForm({
    fc_imagen: "",
  });
  const [cargarAvatar, { isLoading }] = usePostAvatarMutation();

  const onChange = (e: any) => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file);
  };
  const getBase64 = (file: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onLoad(reader.result);
    };
  };
  const onLoad = (fileString: any) => {
    handleChange("fc_imagen", fileString);
    console.log(fileString);
  };
  const buscarTramite = async () => {
    console.log(formulario);
    const avatar = await cargarAvatar({
      ...formulario,
    }).unwrap();
    if (avatar) {
      if (avatar.documento) {
        dispatch(agregarAvatar(avatar.documento));
        toastr.info("Imagen cargada");
      }
      console.log(avatar);
    }
  };
  return (
    <>
      <div className="card">
        <div className="w-full">
          <h1 className="text-md text-primary">Editar Perfil</h1>
          <hr className="my-2 border-primary" />
        </div>
        <div className="grid grid-cols-1 place-items-stretch gap-4 md:grid-cols-3">
          <div className="flex flex-col px-6 py-2">
            <Label htmlFor="fi_busqueda_id" className="!text-sm">
              Nombre:
            </Label>
            {usuario && (
              <Label htmlFor="fi_busqueda_id" className="my-2">
                {usuario.nombre?.toUpperCase()}{" "}
                {usuario.apellido_paterno?.toUpperCase()}{" "}
                {usuario.apellido_materno?.toUpperCase()}
              </Label>
            )}
            <Label htmlFor="fi_busqueda_id" className="!text-sm">
              Area:
            </Label>
            {usuario && (
              <Label htmlFor="fi_busqueda_id" className="my-2">
                {usuario.area?.toUpperCase()}
              </Label>
            )}
            <Label htmlFor="fi_busqueda_id" className="!text-sm">
              Perfil:
            </Label>
            {usuario && (
              <Label htmlFor="fi_busqueda_id" className="my-2">
                {usuario.profile?.toUpperCase()}
              </Label>
            )}
            <Label htmlFor="fi_busqueda_id" className="!text-sm">
              Correo:
            </Label>
            {usuario && (
              <Label htmlFor="fi_busqueda_id" className="my-2">
                {usuario.email?.toUpperCase()}
              </Label>
            )}
          </div>
          <div className="flex flex-col px-6 py-2">
            <label
              htmlFor={"small_size_"}
              className={
                true
                  ? "flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-primary-light bg-primary-light  dark:border-primary-light dark:bg-primary-light"
                  : "group flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-primary-light bg-primary hover:bg-primary-light dark:border-primary-light dark:bg-primary-ultra-light dark:hover:border-primary-light dark:hover:bg-primary-light"
              }
            >
              <div className="flex flex-col items-center justify-center pb-3 pt-3">
                <svg
                  className={
                    true
                      ? "mb-4 h-8 w-8 text-white"
                      : "mb-4 h-8 w-8 text-gray-500 group-hover:text-white dark:text-gray-400"
                  }
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>
                <p
                  className={
                    true
                      ? "mb-2 text-sm text-white group-hover:text-white dark:text-white"
                      : "mb-2 text-sm text-gray-500 group-hover:text-white dark:text-gray-400"
                  }
                >
                  <span className="font-semibold">Click</span> para cargar
                  imagen de perfil{" "}
                </p>
              </div>
              <input
                id={"small_size_"}
                onChange={(e) => onChange(e)}
                type="file"
                className="hidden"
              />
            </label>
          </div>
          <div className="mt-6 flex flex-col px-6 py-2">
            {isLoading && <Spinner />}
            {!isLoading && (
              <Button onClick={buscarTramite}>Actualizar Perfil</Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
