import * as React from "react";
import { Icon, IIconProps } from "./Icon";
import { Logo } from "./Logo";
import { AnimatePresence, motion } from "framer-motion";
import { Popover, PopoverContent, PopoverTrigger } from "./Popover";
import * as Portal from "@radix-ui/react-portal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { agregarToken, cerrarMenu } from "../../features/ui/uiSlice";

import logo from "/images/amex_insuranceservices_logo_v.svg";
import { useGetPermisosQuery } from "../../services/docs";
import { Spinner } from "../Spinner";

// ---
// Menú que contiene el menú de escritorio y el menú de móvil
// ---
export interface IMenuProps {
  items: IItemMenuProps[];
}

const Menu = (): JSX.Element => {
  const { data, isLoading } = useGetPermisosQuery();
  return (
    <div className="sticky left-0 top-0 flex min-h-full w-min flex-row items-start justify-start gap-1 bg-light shadow-right">
      <div className="relative h-full w-full">
        {isLoading && <Spinner />}
        {data && (
          <>
            <MenuEscritorio items={data.menu} />
            <MenuMovil items={data.menu} />
          </>
        )}
      </div>
    </div>
  );
};

// ---
// End of Menú que contiene el menú de escritorio y el menú de móvil
// ---

// ---
// Menú de escritorio
// ---

export interface IMenuEscritorioProps {
  items: IItemMenuProps[];
}

export const MenuEscritorio = ({
  items,
}: IMenuEscritorioProps): JSX.Element => {
  const { usuario, menu } = useSelector((state: RootState) => state.ui);
  const dispatch = useDispatch();
  const cambiarMenu = () => {
    dispatch(cerrarMenu());
  };
  return (
    <div
      className={
        menu
          ? "relative hidden h-full w-[240px] shrink-0 flex-col items-start justify-start gap-6 pb-0 pl-0 pr-0 pt-6 md:flex"
          : "relative hidden h-full w-[70px] shrink-0 flex-col items-start justify-start gap-6 pb-0 pl-0 pr-0 pt-6 md:flex"
      }
    >
      <div
        onClick={cambiarMenu}
        className="flex w-full cursor-pointer justify-end pr-5"
      >
        {menu ? (
          <Icon variante={"chevron-left"} />
        ) : (
          <Icon variante={"chevron-right"} />
        )}
      </div>
      <div
        className={
          menu
            ? "relative flex shrink-0 flex-col items-center justify-start gap-2 self-stretch pb-6 pl-4 pr-4 pt-6"
            : "relative flex shrink-0 flex-col items-center justify-start gap-1 self-stretch p-0"
        }
      >
        <img src={logo} />
      </div>

      <div
        className={
          "relative flex shrink-0 flex-row items-start justify-start gap-2 self-stretch pb-0 pl-4 pr-4 pt-0"
        }
      ></div>

      <nav className="w-full space-y-0 overflow-auto">
        {items?.map((item, index) => (
          <MenuItemComponent
            key={index}
            item={item}
            currentPath={location.pathname}
          />
        ))}
      </nav>
    </div>
  );
};

const MenuItemComponent = ({
  item,
  currentPath,
}: {
  item: any;
  currentPath: string;
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const hasSubItems = item.submenus && item.submenus.length > 0;
  const isActive =
    currentPath === item.fc_ruta ||
    item.submenus?.some((subItem: any) => subItem.fc_ruta === currentPath);
  const { menu } = useSelector((state: RootState) => state.ui);
  console.log(menu);

  return (
    <div className="group relative ">
      <Link
        to={!hasSubItems && item.fc_ruta}
        onClick={() => hasSubItems && setIsOpen(!isOpen)}
        className={`flex items-center justify-between p-4 transition-colors hover:bg-gray-100
          ${isActive ? "font-semibold text-primary" : "text-gray-700"}`}
      >
        <div className="flex items-center gap-3">
          <Icon variante={item.fc_icon} />
          {menu && <span>{item.fc_menu}</span>}
        </div>

        {hasSubItems && (
          <motion.span animate={{ rotate: isOpen ? 180 : 0 }} className="ml-2">
            ▼
          </motion.span>
        )}
      </Link>

      <AnimatePresence>
        {hasSubItems && isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            className="pl-6"
          >
            {item.submenus?.map((subItem, index) => (
              <MenuItemComponent
                key={index}
                item={subItem}
                currentPath={currentPath}
              />
            ))}
          </motion.div>
        )}
      </AnimatePresence>

      {isActive && (
        <motion.div
          className="absolute inset-y-0 left-0 w-1 bg-primary"
          layoutId="activeIndicator"
        />
      )}
    </div>
  );
};

// ---
// End of Menú de escritorio
// ---

// ---
// Menú de móvil
// ---

export interface IMenuMovilProps {
  items: IItemMenuProps[];
}
export const MenuMovil = ({ items }): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <Portal.Root>
      <div className={"fixed bottom-4 right-4 !z-[999] md:hidden"}>
        <div className={"relative"}>
          <Popover open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
            <PopoverTrigger asChild>
              {/* Icono de menú */}
              <motion.div
                className={
                  "flex h-12 w-12 cursor-pointer items-center justify-center overflow-hidden rounded-full bg-light shadow-xl"
                }
              >
                <Icon variante={isOpen ? "x" : "menu"} color="dark" size={32} />
              </motion.div>
              {/* End of Icono de menú */}
            </PopoverTrigger>
            <PopoverContent className="mr-6 p-0">
              <motion.div className={"w-full space-y-0 "}>
                {items?.map((item, i) => (
                  <ItemMenu key={i} {...item} />
                ))}
              </motion.div>
            </PopoverContent>
          </Popover>
        </div>
      </div>
    </Portal.Root>
  );
};

// ---
// End of Menú de móvil
// ---

// ---
// Item de menú
// ---

export interface IItemMenuProps {
  estado?: "activo" | "inactivo" | "deshabilitado";
  fc_menu: string;
  fc_icon: IIconProps["variante"];
  onClick: () => void;
  href: string;
}

const ItemMenu = ({
  estado = "activo",
  fc_menu,
  fc_icon,
  href,
}: IItemMenuProps): JSX.Element => {
  const height = 60;
  const location = useLocation();
  return (
    <div className={"relative"}>
      <Link
        to={href || "#"}
        className={
          "relative flex shrink-0 flex-row items-center justify-start gap-4 self-stretch overflow-hidden"
        }
      >
        <motion.div
          className={`relative flex flex-1 flex-row items-center justify-start gap-4 px-4`}
          initial={{ height }}
          animate={{
            height,
            color: estado === "activo" ? "primary" : "dark",
          }}
        >
          <Icon variante={fc_icon} />

          <div className={`relative flex-1 text-left`}>{fc_menu}</div>
        </motion.div>
      </Link>
      {/*  Indicador de activo */}
      <AnimatePresence mode="sync">
        {href === location.pathname && (
          <motion.div
            layoutId="menuItemActivoIndicador"
            initial={{ height }}
            className={`absolute inset-0 z-[0] w-full border-l-4 border-primary-light bg-primary-light/20 md:border-l-0 md:border-r-4`}
          />
        )}
      </AnimatePresence>
      {/*  End of Indicador de activo */}
    </div>
  );
};

// ---
// End of Item de menú
// ---

export default Menu;
