import { useGetDocsListQuery } from "../../../services/docs";
import { useParams } from "react-router-dom";
import { Spinner } from "../../../components/Spinner";
import { ItemEstatusDocumento } from "../../../components/ItemEstatusDocumento";
import { Icon } from "../../../components/ui/Icon";
import { fecha_actual } from "../../../hooks/elapsed";
import { ItemDocumentoComponente } from "../../../components/ItemDocumentoComponent";

export const ExpendienteScreen = () => {
  let { siniestro = 0, tramite_id = 0 } = useParams();
  const { data, isLoading } = useGetDocsListQuery({
    siniestro,
    tramite: tramite_id,
  });
  if (isLoading) {
    return (
      <div className="flex w-full justify-center">
        <Spinner />
      </div>
    );
  }
  return (
    <>
      <div className="mx-auto mb-5 items-center justify-start space-y-4">
        <div className="relative flex w-full flex-row gap-1 border-b-2 border-solid border-gray-ultra-light">
          <div className="relative flex h-12 w-full cursor-pointer flex-row items-center justify-start gap-4 overflow-hidden rounded-t border-b-4 border-solid border-gray-ultra-light bg-light px-2">
            <ItemEstatusDocumento
              color={"bg-gray"}
              label={"Documento No Cargado"}
            />
            <ItemEstatusDocumento
              color={"bg-green-400"}
              label={"Documento Cargado"}
            />
            <ItemEstatusDocumento
              color={"bg-primary"}
              label={"Digital Verificado"}
            />
            <ItemEstatusDocumento
              color={"bg-yellow-400"}
              label={"Digital Rechazado"}
            />
          </div>
        </div>
      </div>
      <div className=" flex w-full">
        <p className="@xl:text-md @lg:text-md text-md w-full border-b-2 border-primary pl-2 pt-5 text-primary">
          Documentos del Titular
        </p>
      </div>
      <div className="grid grid-cols-1 place-items-stretch gap-4 md:grid-cols-2 lg:grid-cols-3">
        {data &&
          data.documentos &&
          data.documentos.length > 0 &&
          data.documentos.map((doc: any) => {
            return <ItemDocumentoComponente doc={doc} />;
          })}
      </div>
      <div className="flex w-full">
        <p className="@xl:text-md @lg:text-md text-md w-full border-b-2 border-primary pl-2 pt-5 text-primary">
          Documentos Adicionales
        </p>
      </div>
      <div className="grid grid-cols-3 place-items-stretch gap-4">
        {data &&
          data.adicionales &&
          data.adicionales.length > 0 &&
          data.adicionales.map((doc: any) => {
            return <ItemDocumentoComponente doc={doc} />;
          })}
      </div>
    </>
  );
};
