import { useNavigate, useParams } from "react-router-dom";
import {
  useAgregarDocumentoMutation,
  useCargaDocumentoMutation,
  useEditarDocumentoMutation,
  useGetDocsListQuery,
  useGetEnviarWalkawayMutation,
  useGetValidacionMutation,
  useLazyGetDocsListQuery,
} from "../../../services/docs";
import { ItemEstatusDocumento } from "../../../components/ItemEstatusDocumento";
import { ItemDocumentoCarga } from "../../../components/ItemDocumentoCarga";
import { Spinner } from "../../../components/Spinner";
import Button from "../../../components/ui/Button";
import { toastr } from "react-redux-toastr";

export const CargaDocumentosScreen = () => {
  let { siniestro, tramite_id } = useParams();
  const { data, isLoading } = useGetDocsListQuery({
    siniestro,
    tramite: tramite_id,
  });
  const [
    validarSend,
    { isLoading: isLoadingCarga, error: errorCarga, isSuccess, reset },
  ] = useGetValidacionMutation();
  const [
    walkawaySend,
    { isLoading: isLoadingWalkaCarga, error: errorWalkaCarga },
  ] = useGetEnviarWalkawayMutation();
  const [eliminar, { isLoading: eliminarLoading }] =
    useEditarDocumentoMutation();
  const [tigger] = useLazyGetDocsListQuery();

  const navigate = useNavigate();
  const eliminarDocumento = async (doc) => {
    const resp = await eliminar({
      fc_siniestro: siniestro,
      fi_tramite_id: +tramite_id,
      fi_documento_id: doc.ref_fi_documento_id,
      fi_documento_activo: 1,
    }).unwrap();
    if (resp) {
      toastr.error(resp.message);
      tigger({ siniestro, tramite: tramite_id });
    }
  };

  const valor: any = [];
  if (isLoading) {
    return (
      <div className="flex w-full justify-center">
        <Spinner />
      </div>
    );
  }
  const enviarAValidar = async () => {
    const resp = await validarSend({ siniestro, tramite_id: +tramite_id })
      .unwrap()
      .catch((err: any) => {
        console.log(err);
        if (err.status === 400) {
          if (err.data.message) {
            if (Array.isArray(err.data.message)) {
              err.data.message.map((m: any) => {
                toastr.error(m);
              });
            } else {
              toastr.error(err.data.message);
            }
          }
        } else {
          toastr.error(err.data.message);
        }
      });
    if (resp) {
      toastr.info(resp.message);
      navigate("/view/siniestro/" + siniestro + "/" + tramite_id);
    }
  };
  const enviarWalkaway = async () => {
    const resp = await walkawaySend({ siniestro, tramite_id: +tramite_id })
      .unwrap()
      .catch((err: any) => {
        console.log(err);
        if (err.status === 400) {
          if (err.data.message) {
            if (Array.isArray(err.data.message)) {
              err.data.message.map((m: any) => {
                toastr.error(m);
              });
            } else {
              toastr.error(err.data.message);
            }
          }
        } else {
          toastr.error(err.data.message);
        }
      });
    if (resp) {
      toastr.info(resp.message);
      navigate("/view/siniestro/" + siniestro + "/" + tramite_id);
    }
  };
  return (
    <div className="mx-auto mb-5 items-center justify-start space-y-4">
      <div className="relative flex w-full flex-row gap-1 border-b-2 border-solid border-gray-ultra-light">
        <div className="relative flex h-12 w-full cursor-pointer flex-row items-center justify-start gap-4 overflow-hidden rounded-t border-b-4 border-solid border-gray-ultra-light bg-light px-2">
          <ItemEstatusDocumento
            color={"bg-gray"}
            label={"Documento No Cargado"}
          />
          <ItemEstatusDocumento
            color={"bg-green-400"}
            label={"Documento Cargado"}
          />
          <ItemEstatusDocumento
            color={"bg-primary"}
            label={"Digital Verificado"}
          />
          <ItemEstatusDocumento
            color={"bg-yellow-400"}
            label={"Digital Rechazado"}
          />
        </div>
      </div>
      {!isLoadingCarga && data && data.validacion && (
        <div className="flex w-full justify-center">
          <Button onClick={enviarAValidar}>Enviar a validar</Button>
        </div>
      )}
      {!isLoadingCarga && !isLoadingWalkaCarga && data && data.btnWalkaway && (
        <div className="flex w-full justify-center">
          <Button onClick={enviarWalkaway}>Enviar a Walkaway</Button>
        </div>
      )}
      {isLoadingWalkaCarga && (
        <div className="flex w-full justify-center">
          <Spinner />
        </div>
      )}
      {isLoadingCarga && (
        <div className="flex w-full justify-center">
          <Spinner />
        </div>
      )}
      <h1>Documentos Titular</h1>
      <div className="grid grid-cols-3 place-items-stretch gap-4">
        {data &&
          data.documentos &&
          data.documentos.map((doc: any, i: number) => {
            valor.push({ imagen: "" });
            let c: any = {
              ...doc,
              cargado: false,
            };
            return (
              <ItemDocumentoCarga
                key={doc.id}
                valor={valor}
                doc={c}
                i={i}
                siniestro={siniestro}
                tramite_id={tramite_id}
                doc_adicional={data.adicionales}
              />
            );
          })}
      </div>
      {data && data.adicionales && data.adicionales.length > 0 && (
        <>
          <h1>Adicionales</h1>
          <div className="grid grid-cols-3 place-items-stretch gap-4">
            {data &&
              data.adicionales &&
              data.adicionales.map((doc: any, i: number) => {
                valor.push({ imagen: "" });
                let c: any = {
                  ...doc,
                  cargado: false,
                };
                return (
                  <ItemDocumentoCarga
                    key={doc.id}
                    valor={valor}
                    doc={c}
                    i={i}
                    siniestro={siniestro}
                    tramite_id={tramite_id}
                    doc_adicional={data.adicionales}
                  />
                );
              })}
          </div>
        </>
      )}

      {data &&
        data.documentosBorrados &&
        data.documentosBorrados.length > 0 && (
          <>
            <h1>Documentos Eliminados</h1>
            <div className="grid grid-cols-3 place-items-stretch gap-4">
              {data &&
                data.documentosBorrados &&
                data.documentosBorrados.map((doc: any) => {
                  return (
                    <div
                      key={doc.id}
                      className="flex-column flex w-full items-center rounded-xl border bg-white p-2 shadow"
                    >
                      <div className="w-1/2">{doc.dc_fc_nombre_documento}</div>
                      <div className="w-1/2">
                        {!eliminarLoading && (
                          <Button
                            onClick={() => eliminarDocumento(doc)}
                            className="bg-danger"
                          >
                            Reactivar Documento
                          </Button>
                        )}
                        {eliminarLoading && <Spinner />}
                      </div>
                    </div>
                  );
                })}
            </div>
          </>
        )}
    </div>
  );
};
